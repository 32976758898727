<template>
    <div data-scroll :data-scroll-id="name" class="layout-10">
        <div
            v-observer
            :style="{ background: item.background }"
            class="content-container"
        >
            <div class="text-content">
                <header-title
                    :name="item.contentTitle"
                    :underline-color="item.color"
                    text-color="white"
                />
                <div class="description" v-html="item.contentText"></div>
            </div>
            <img :src="item.image" class="image" alt="" />
        </div>
        <div class="background-container">
            <img
                :src="item.coverImage"
                data-scroll
                data-scroll-id="imageParallax-1"
                class="background-image"
                alt=""
            />
        </div>
    </div>
</template>

<script>
import first from "lodash/first";
import last from "lodash/last";
import HeaderTitle from "@/components/custom/HeaderTitle";
import { mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        HeaderTitle
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        item() {
            return first(
                this.data?.list?.map(item => {
                    let image = first(item?.image)?.devices?.[
                        this.device?.type
                    ];
                    let coverImage = last(item?.image)?.devices?.[
                        this.device?.type
                    ];
                    return {
                        background: item.background_color,
                        image,
                        coverImage,
                        contentTitle: first(item?.content?.tabs)?.title,
                        contentText: first(item?.content?.tabs)?.content,
                        color: item.color1
                    };
                })
            );
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.layout-10 /deep/ {
    position: relative;
    margin-top: 144px;

    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        margin-top: 89px;
    }
    @media only screen and (max-width: 767px) {
        margin-top: 55px;
    }
    @media only screen and (max-width: 1023px) {
        padding: 30px 0 20px;
    }

    .content-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 21px;
        color: #0a141c;
        text-align: left;
        grid-gap: 50px;
        padding: 0 195px;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            padding: 0 165px;
            grid-gap: 30px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            padding: 0 128px;
            grid-gap: 30px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 768px) {
            padding: 0 47px;
            grid-gap: 30px;
        }
        @media only screen and (max-width: 1023px) {
            flex-direction: column-reverse;
            transform: unset;
            top: unset;
            position: relative;
            z-index: 1;
        }

        @media only screen and (max-width: 767px) {
            padding: 0 16px;
        }

        .text-content {
            width: 50%;
            min-width: 50%;
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                // width: 450px;
                width: unset;
            }
            @media only screen and (max-width: 1023px) {
                width: 100%;
            }

            .header-title {
                font-weight: normal;
            }

            .description {
                margin-top: 55px;
                color: white;
                font-size: 21px;
                font-weight: normal;

                p,
                span,
                h2,
                div {
                    font-family: "Noto-Sans", "Noto-Sans-georgian";
                }
                @media only screen and (max-width: 1366px) and (min-width: 769px) {
                    margin-top: 34px;
                }
                @media (max-width: 1280px) {
                    font-size: 16px;
                }
                @media only screen and (max-width: 768px) {
                    margin-top: 21px;
                }
            }
        }

        .image {
            width: 500px;

            @media only screen and (max-width: 1440px) and (min-width: 1366px) {
                width: 400px;
            }
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                width: 330px;
                margin-left: 66px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                width: 240px;
            }
            @media only screen and (max-width: 767px) {
                width: 150px;
            }
        }
    }

    .background-container {
        background: #0a141c;
        z-index: -1;
        width: 100%;
        max-height: 1200px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        pointer-events: none;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            max-height: 915px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            max-height: 868px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            max-height: 651px;
        }
        @media only screen and (max-width: 1023px) {
            position: absolute;
            top: 0;
            z-index: 0;
            max-height: unset;
            height: 100%;
        }

        .background-image {
            width: 100%;
            object-fit: cover;
            opacity: 0.2;
            @media only screen and (max-width: 1023px) {
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
</style>
